import React, { useEffect, useState } from "react";
import { observer } from "mobx-react-lite";
import { useStores } from "_common/hooks";

import { Link, useNavigate, useSearchParams } from "react-router-dom";
import HeaderTitle from "_common/component/Layout/Header/HeaderTitle";
import Collapse from "_common/component/Collapse";
import { formatNumber } from "_common/utils/formatValue";
import classNames from "classnames";
import { NotificationManager } from "react-notifications";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { PaymentApi } from "states/api/payment";
import { BankApi } from "states/api/bank";
import { handleCopyLink } from "_common/utils/helper";

const DepositBankInfo = observer(() => {
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const {
    authStore: { user, user_wallet, general },
  } = useStores();
  const [params] = useSearchParams();
  const amount = Number(params.get("money") || 0);

  const { data, refetch } = useQuery(
    ["bank", "detail"],
    () =>
      BankApi.getDetail({
        params: {
          type: "system",
        },
      }),
    {
      staleTime: 300000,
    }
  );

  const { mutate: onSubmit } = useMutation(
    (variables) =>
      PaymentApi.deposit({
        params: {
          ...variables,
          amount,
          bank_method: "banking",
          token_id: 3,
          type: "deposit",
        },
      }),
    {
      onSuccess: (res) => {
        queryClient.invalidateQueries(["get_profile"]);
        navigate(`/payment/deposit/success?money=${amount}`);
      },
      onError: (error) => {
        const errorMessage =
          i18next.t(error?.message) ?? i18next.t("action_fail_msg");
        NotificationManager.error(errorMessage);
      },
    }
  );

  useEffect(() => {
    refetch();
  }, []);

  const handleSubmit = () => {
    if (amount < Number(general?.min_deposit || 0))
      return NotificationManager.error(
        `Vui lòng nhập số tiền lớn hơn ${formatNumber(general?.min_deposit)}`
      );
    if (amount > Number(general?.max_deposit || 0))
      return NotificationManager.error(
        `Vui lòng nhập số tiền nhỏ hơn ${formatNumber(general?.min_deposit)}`
      );

    onSubmit();

    return null;
  };

  return (
    <>
      <HeaderTitle title=" Thanh toán bằng thẻ ngân hàng" />

      <div className="payment-container">
        <p className="title">Vui lòng nhập thông tin chuyển khoản</p>
        <p className="des">
          Sau đây là thông tin tài khoản ngân hàng bạn cần chuyển khoản
        </p>
        <div className="bank-info">
          <div className="item">
            <span>Bank:</span>
            <div className="action">
              <span>
                {data?.bank_name ||
                  "Vui lòng liên hệ bộ phận chăm sóc khách hàng"}
              </span>
              {data?.bank_name && (
                <button
                  type="button"
                  className="btn btn-copy"
                  onClick={() => handleCopyLink(data?.bank_name)}
                >
                  Sao chép
                </button>
              )}
            </div>
          </div>
          <div className="item">
            <span>Tên người nhận tiền:</span>
            <div className="action">
              <span>
                {data?.bank_owner ||
                  "Vui lòng liên hệ bộ phận chăm sóc khách hàng"}
              </span>
              {data?.bank_owner && (
                <button
                  type="button"
                  className="btn btn-copy"
                  onClick={() => handleCopyLink(data?.bank_owner)}
                >
                  Sao chép
                </button>
              )}
            </div>
          </div>
          <div className="item">
            <span>Tài khoản::</span>
            <div className="action">
              <span>
                {data?.bank_number ||
                  "Vui lòng liên hệ bộ phận chăm sóc khách hàng"}
              </span>
              {data?.bank_owner && (
                <button
                  type="button"
                  className="btn btn-copy"
                  onClick={() => handleCopyLink(data?.bank_number)}
                >
                  Sao chép
                </button>
              )}
            </div>
          </div>
        </div>
        <div className="inputMoney">
          <span className="label">Số tiền:</span>
          <span className="money">{formatNumber(amount)}</span>
        </div>
        <button
          type="button"
          className={classNames("btn btn-sub active")}
          onClick={handleSubmit}
        >
          Bước tiếp theo
        </button>
      </div>
    </>
  );
});

export default DepositBankInfo;
