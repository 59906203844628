import React, { useEffect, useMemo, useRef, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useStores } from "_common/hooks";
import lhc from "assets/img/lhc.png";
import { formatNumber } from "_common/utils/formatValue";
import moment from "moment";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faClock } from "@fortawesome/free-solid-svg-icons";
import i18next from "i18next";

function randomInteger(min, max) {
  return Math.floor(Math.random() * (max - min + 1) + min);
}

export default function GameHis({ gameInfo }) {
  const navigate = useNavigate();
  const {
    authStore: { avatars, item_ball, item_number },
  } = useStores();

  const [ran, setRan] = useState();
  const [listItem, setListItem] = useState();

  const randomFunct = () => {
    const lstName = [...listItem];
    const indexAvatar = randomInteger(0, avatars.length - 1);
    const money = randomInteger(100, 100000);
    const code = randomInteger(100, 999);
    const listBet = [...item_ball, ...item_number];
    const indexBet = randomInteger(0, listBet.length - 1);

    lstName.push({
      id: new Date().getTime(),
      game_no: gameInfo?.game_no,
      bet: listBet[indexBet]?.name,
      avatar: avatars[indexAvatar],
      code,
      money: money / 1.5,
      time: moment().format("HH:mm:ss"),
    });

    setListItem(lstName);
  };

  useEffect(() => {
    const lstName = [];

    for (let index = 0; index < 30; index += 1) {
      const indexAvatar = randomInteger(0, avatars.length - 1);
      const code = randomInteger(100, 999);
      const money = randomInteger(100, 100000);
      const timeRandom = randomInteger(10, 30);
      const listBet = [...item_ball, ...item_number];
      const indexBet = randomInteger(0, listBet.length - 1);

      lstName.push({
        id: index,
        game_no: gameInfo?.game_no,
        avatar: avatars[indexAvatar],
        bet: listBet[indexBet]?.name,
        code,
        money: money / 1.5,
        time: moment()
          .subtract("minutes", Math.round((30 - index) / 15))
          .add("seconds", timeRandom)
          .format("HH:mm:ss"),
      });
    }
    setListItem(lstName);
  }, [gameInfo?.game_no]);

  useEffect(() => {
    const interval = setInterval(() => {
      setRan(new Date().getTime());
      randomFunct();
    }, 4000);

    return () => {
      clearInterval(interval);
    };
  }, [ran, listItem]);

  return (
    <div className="player-list ">
      <table className="uni-table">
        <thead>
          <tr>
            <th>{i18next.t("account")}</th>
            <th>{i18next.t("game_no")}</th>
            <th>{i18next.t("category")}</th>
            <th>{i18next.t("bet_amount")}</th>
            <th>{i18next.t("status")}</th>
          </tr>
        </thead>
      </table>
      <table className="uni-table inner-wrap targetUp">
        <tbody>
          {listItem?.map((item, index) => (
            <tr className="winner-item" key={`item-${index.toString()}`}>
              <td>****{item?.code}</td>
              <td>{item?.game_no}</td>
              <td>{item?.bet}</td>
              <td>{formatNumber(item?.money, "0,0.00")}</td>
              <td className="text blue">{i18next.t("follow")}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
}
